
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'WhyBlock',
  data () {
    return {
      translateData: {}
    }
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('explanation_4_header', 'made_by_ut', 'explanation_4_1', 'explanation_4_2', 'explanation_4_3', 'explanation_4_4', 'explanation_4_5', 'explanation_4_6', 'explanation_4_6_italic', 'explanation_4_7')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
