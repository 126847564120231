import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1ed8d260"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "step-three"
}
const _hoisted_2 = { class: "step-three__step" }
const _hoisted_3 = { lass: "step-three__step-number" }
const _hoisted_4 = { class: "step-three__container" }
const _hoisted_5 = { class: "step-three__left-text" }
const _hoisted_6 = { class: "step-three__right-text" }
const _hoisted_7 = { class: "step-three__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GroupIcon = _resolveComponent("GroupIcon")!
  const _component_HandIcon = _resolveComponent("HandIcon")!

  return (_ctx.translateData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, "3 " + _toDisplayString(this.translateData.step), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", null, [
              _createVNode(_component_GroupIcon)
            ]),
            _createElementVNode("div", null, _toDisplayString(this.translateData.explanation_3_1), 1)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createVNode(_component_HandIcon)
            ]),
            _createElementVNode("div", _hoisted_7, _toDisplayString(this.translateData.explanation_3_2), 1)
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}