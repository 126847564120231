
import { defineComponent } from 'vue'

import UserHeader from '@/components/molecules/UserHeader.vue'
import AuthButtonGroup from '@/components/molecules/AuthButtonGroup.vue'
import StepsXSeven from '@/components/molecules/StepsXSeven.vue'
import FooterUT from '@/components/molecules/FooterUT.vue'
import StepOne from '@/components/molecules/StepOne.vue'
import StepTwo from '@/components/molecules/StepTwo.vue'
import StepThree from '@/components/molecules/StepThree.vue'
import WhyBlock from '@/components/molecules/WhyBlock.vue'
import HowItWorkLastBlock from '@/components/molecules/HowItWorkLastBlock.vue'
// import StatisticBlock from '@/components/molecules/StatisticBlock.vue'

export default defineComponent({
  name: 'HowItWorksSection',
  data () {
    return {
      translateData: {}
    }
  },
  components: {
    UserHeader,
    AuthButtonGroup,
    StepsXSeven,
    FooterUT,
    StepOne,
    StepTwo,
    StepThree,
    WhyBlock,
    HowItWorkLastBlock
    // StatisticBlock
  },
  mounted () {
    this.translate()
  },
  methods: {
    translate (data) {
      data = []
      data.push('slogan', 'sub_slogan', 'join_now')
      this.$store.dispatch('getPayments/translate', data).then(
        (response) => {
          this.translateData = response
        },
        (error) => {
          console.log(error)
        }
      )
    }
  }
})
